import React from 'react'

export default function Latestproject() {
  return (
    <>
      <div className="container-fluid bg-container">
        <div className="row">
          <div className="col bg-image"></div>
        </div>
        <div className="row main-content">
          <div className="col">
            <h4 className='pt-2'>   تفاصيل المشرع </h4>
          </div>
        </div>
        </div>
        <div class="container text-end mt-5">
  <div class="row">
    <div class="col-md-6">
        <img className='w-50 mt-5 d-flex justify-content-center' src="https://images.pexels.com/photos/93400/pexels-photo-93400.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
    </div>
    <div class="col-md-6">

    <h3 className='mt-5'>  مشروع فلا صلبوخ</h3>
    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Labore quas officia impedit et illum repellat sint, dolorum perspiciatis, velit minus ratione porro ipsum sunt quos consectetur perferendis provident eius reiciendis?</p>
    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Labore quas officia impedit et illum repellat sint, dolorum perspiciatis, velit minus ratione porro ipsum sunt quos consectetur perferendis provident eius reiciendis?</p>

    </div>
  </div>
  
</div>
  
    </>
  )
}
