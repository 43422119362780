import men_doing_concrete from "./assets/men_doing_concrete.jpeg";
import loaders from "./assets/loaders.jpeg";
import mockup from "./assets/mockup.jpeg";
import logo from "./assets/logo.png";

export default {
  loaders,
  men_doing_concrete,
  mockup,
  logo
};
